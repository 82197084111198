import React from 'react';
import { StorytellingSection, Section, Article, Heading, Link, TextAccent } from '../../../components';
import { Introduction, Content, Logo } from '../Work.style';
import logo from '../../../images/logo-blueberry.svg';
import ScrumSpace from './ScrumSpace';
import Panoram from './Panoram';
import RivieraTour from './RivieraTour';
import Echo from './Echo';

/**
 * Work: Blueberry composition
 */
const Blueberry = () => (
  <StorytellingSection offsetKind="container" whiteSpaceSize={1}>
    <Introduction id="section:blueberry">
      <Content>
        <Section>
          <Article>
            <Heading element="h2" weight={500} colorKind="neutral">
              <Logo src={logo} alt="Blueberry logo" />
              <Link to="https://blueberry.io">Blueberry</Link>, remote adventures and exquisite Czech cuisine.
            </Heading>
            <TextAccent withSpacing>
              I served as a JavaScript Developer for Blueberry, helping build many exciting internal and external
              products. Having the opportunity to work remotely, I managed to continually improve myself in challenges
              remote work entails, while occasionally visiting Prague’s office, for meeting new clients and tightening
              collaboration with Czech colleagues.
            </TextAccent>
            <TextAccent>
              I was involved in defining project architecture and making decisions on the way the projects should
              evolve. I primarily worked on React, and React Native based projects. To evaluate the effectiveness of our
              software teams, and the craft of software development, we honed the points outlined in the Joel test and
              used bi-weekly agile sprints. We also experimented with various “fresh” technologies, such as
              face-recognition and ReasonML.
            </TextAccent>
          </Article>
        </Section>
      </Content>
    </Introduction>
    <Echo />
    <Panoram />
    <ScrumSpace />
    <RivieraTour />
  </StorytellingSection>
);

export default Blueberry;
